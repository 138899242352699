import DialogAvatar from './DialogAvatar'
import DialogMeta from './DialogMeta'
import DialogContent from './DialogContent'
import DialogBadge from './DialogBadge'
import PropTypes from 'prop-types'
import { Star, ReduceOne } from '@icon-park/react'
import './DialogListItem.css'

export default function DialogListItem(props) {
  const {
    title,
    lastMsgDate,
    lastMsgType,
    lastMsgContent,
    unreadMsgCount,
    avatar,
    avatarColor,
    status,
    isCollect,
    isBlock,
    isGroupChat,
    banType,
    otherLeave,
  } = props

  return (
    <div className="dialog-list-item-wrapper">
      <DialogAvatar
        avatarColor={avatarColor}
        title={title}
        size={50}
        src={avatar}
        status={otherLeave ? '' : status}
        isGroupChat={isGroupChat}
      />

      <div className="dialog-inner-wrapper">
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <div className="dialog-title-wrapper">
            <span className="dialog-title-span">
              {isCollect && <Star style={{ marginRight: 5 }} theme="filled" size="14" fill="#F5A623" />}
              {isBlock && <ReduceOne style={{ marginRight: 5 }} theme="filled" size="14" fill="#f52323" />}
              <span className={`${banType === 'permanent_ban' ? 'is-ban' : ''} ${otherLeave ? 'otherLeave' : ''}`}>
                {title}
              </span>
            </span>
          </div>
          {lastMsgDate && <DialogMeta lastMsgDate={lastMsgDate} />}
        </div>

        <div className="second-row">
          {lastMsgType === 'text' && <DialogContent content={lastMsgContent} />}
          {lastMsgType === 'dice' && <DialogContent content="[骰子]" />}
          {lastMsgType === 'image' && <DialogContent content="[图片]" />}
          {lastMsgType === 'video' && <DialogContent content="[视频]" />}
          {lastMsgType === 'audio' && <DialogContent content="[语音]" />}
          {lastMsgType === 'group_invite' && <DialogContent content="[群聊邀请]" />}
          {!lastMsgType && <DialogContent content="&nbsp;" />}

          <DialogBadge unreadMsgCount={unreadMsgCount} />
        </div>
      </div>
    </div>
  )
}

DialogListItem.propTypes = {
  title: PropTypes.string,
  lastMsgDate: PropTypes.number,
  content: PropTypes.string,
  unreadMsgCount: PropTypes.number,
}
