import { Badge } from 'antd-mobile'
import Avatar from 'react-avatar'
import { Avatar as AvatarAntD } from 'antd-mobile'

export default function DialogAvatar(props) {
  const { size = 50, title, src, status, isGroupChat, avatarColor } = props

  const statusMap = {
    online: '#38b503',
    offline: '#a6a6a6',
  }

  return (
    <>
      {isGroupChat ? (
        <Avatar size={size} name={title} round="6px" textSizeRatio={2} color={avatarColor || '#e63470'} />
      ) : (
        <Badge
          bordered
          color={statusMap[status]}
          content={statusMap[status] ? Badge.dot : null}
          style={{ '--right': '0', '--top': '100%' }}>
          <AvatarAntD src={src || ''} size={size} name={title} round="6px" />
        </Badge>
      )}
    </>
  )
}
