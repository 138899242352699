import { Badge } from 'antd-mobile'

import PropTypes from 'prop-types'

export default function DialogBadge(props) {
  const { unreadMsgCount } = props
  return unreadMsgCount ? <Badge content={unreadMsgCount + ''} /> : <></>
}

DialogBadge.propTypes = {
  unreadMsgCount: PropTypes.number,
}
