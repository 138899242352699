import { useTranslation } from 'react-i18next'
import './EmptyList.css'

export default function EmptyList(props) {
  const { isTryUser } = props
  let msg = '试试虚拟人物 或 匹配陌生人吧'
  if (isTryUser) {
    msg = '您当前是试用用户，仅可通过 “设置页-分享链接” 建立与别人的聊天，快去设置页绑定激活吧'
  }

  const { t } = useTranslation()

  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', height: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
        <div className="placeholder-meta-wrapper">
          <div className="placeholder-meta">{msg}</div>
        </div>
      </div>
    </div>
  )
}
