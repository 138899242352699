import './DialogMeta.css'

export default function DialogMeta(props) {
  const { lastMsgDate } = props

  // 如果是今天展示 时:分，否者展示 月/日
  function getDate(ts) {
    const date = new Date(ts)
    const now = new Date()
    if (date.getFullYear() === now.getFullYear()) {
      if (date.getMonth() !== now.getMonth() || date.getDate() !== now.getDate()) {
        return date.format('MM/dd')
      } else {
        return date.format('hh:mm')
      }
    } else {
      return date.format('yyyy/MM/dd')
    }
  }
  return <div className="dialog-meta">{getDate(lastMsgDate)}</div>
}
