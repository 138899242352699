import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import {
  createGroupChatApi,
  getGroupChatEditInfoApi,
  updateGroupChatApi,
  generateGroupChatUrlApi,
  deleteGroupChatUrlApi,
} from '@/services/chat'
import { Button, Form, Input, Toast } from 'antd-mobile'
import copy from 'copy-to-clipboard'

function GroupChatEditor(props, ref) {
  const { panelType, onFinish, addChat, chatid, updateChat, isManager } = props
  const [form] = Form.useForm()

  function getInviteUrl(name, token, password) {
    const url =
      `nml:/ 复制下面链接，浏览器打开，进入群聊【${name} | 缘起聊天】\n` +
      `${window.location.origin}/chat?join_group=${token}\n` +
      `口令：${!password || password === '' ? '(无需口令)' : password}\n` +
      '(软件内直接发送该内容将自动识别成加入群聊的入口)'
    return url
  }

  async function handleSave() {
    const name = form.getFieldValue('name')
    const maxMemberCount = form.getFieldValue('maxMemberCount')
    const password = form.getFieldValue('password')

    if (!name || name === '') {
      Toast.show({ icon: 'fail', content: '请输入群聊名称' })
      return
    }
    if (!maxMemberCount || maxMemberCount === '') {
      Toast.show({ icon: 'fail', content: '请输入群聊最大成员数' })
      return
    }
    if (panelType === 'create') {
      // 创建群聊
      const { code, msg, data } = await createGroupChatApi(name, Number(maxMemberCount), password)
      if (code !== 200) {
        Toast.show({ icon: 'fail', content: msg })
        return
      }
      const name1 = form.getFieldValue('name')
      const password1 = form.getFieldValue('password')
      copy(getInviteUrl(name1, data.token, password1))
      Toast.show('群组邀请链接已复制到剪切板')
      // 补充新聊天的信息
      data.is_group_chat = true
      addChat(data)

      form.setFieldValue('name', '')
      form.setFieldValue('maxMemberCount', 20)
      form.setFieldValue('password', '')
    } else if (panelType === 'edit') {
      if (isManager) {
        // 修改群聊信息
        const { code, msg } = await updateGroupChatApi(chatid, name, Number(maxMemberCount), password)
        if (code !== 200) {
          Toast.show({ icon: 'fail', content: msg })
          return
        }
        updateChat(chatid, { title: name })
        Toast.show('更新群组信息成功')
      }
    }
    onFinish()
  }

  useEffect(() => {
    async function fetchData() {
      if (panelType === 'edit') {
        const { code, data, msg } = await getGroupChatEditInfoApi(chatid, isManager)
        if (code !== 200) {
          Toast.show(msg)
          return
        }
        form.setFieldValue('name', data.title || '')
        form.setFieldValue('maxMemberCount', data.max_member_count || 10)
        form.setFieldValue('password', data.password || '')
        form.setFieldValue('token', data.token || '')
      }
    }
    fetchData()
  }, [])

  useImperativeHandle(ref, () => ({
    form,
    panelType,
  }))

  async function handleGetGroupUrl(e) {
    let token = form.getFieldValue('token')
    if (!token || token === '') {
      if (!isManager) {
        Toast.show({ icon: 'fail', content: '请先联系群主生成邀请链接' })
        return
      }
      const { data, code, msg } = await generateGroupChatUrlApi(chatid)
      if (code !== 200) {
        Toast.show({ icon: 'fail', content: msg })
        return
      }
      token = data
      form.setFieldValue('token', token)
    }
    // 复制到剪切板
    const name = form.getFieldValue('name')
    const password = form.getFieldValue('password')
    copy(getInviteUrl(name, token, password))
    Toast.show('群组邀请链接已复制到剪切板')
  }

  async function handleDeleteGroupUrl(e) {
    const token = form.getFieldValue('token')
    if (!token || token === '') return
    const { code, msg } = await deleteGroupChatUrlApi(chatid)
    if (code !== 200) {
      Toast.show({ icon: 'fail', content: msg })
      return
    }
    Toast.show('邀请链接已删除')
    form.setFieldValue('token', '')
  }

  return (
    <div>
      <Form
        form={form}
        onFinish={handleSave}
        initialValues={{ name: '', maxMemberCount: 10 }}
        footer={
          <div style={{ display: 'flex', padding: 0 }}>
            <Button onClick={onFinish} block>
              取消
            </Button>
            <Button block type="submit" color="primary">
              确定
            </Button>
          </div>
        }>
        <Form.Item
          name="name"
          label="群聊名称"
          rules={[
            {
              min: 1,
              max: 10,
              type: 'string',
              message: '名称长度在10以内',
            },
          ]}>
          <Input placeholder="请输入" disabled={!isManager && panelType !== 'create'} />
        </Form.Item>

        <Form.Item
          name="maxMemberCount"
          label="最大成员数"
          rules={[
            {
              type: 'number',
              message: '请输入数字',
              transform: (value) => {
                return Number(value)
              },
            },
          ]}>
          <Input placeholder="请输入数字" disabled={!isManager && panelType !== 'create'} />
        </Form.Item>

        <Form.Item
          name="password"
          label="口令"
          rules={[
            {
              min: 1,
              max: 10,
              type: 'string',
              message: '口令长度在10以内',
            },
          ]}>
          <Input disabled={panelType !== 'create' && !isManager} placeholder="不填写则不需要口令" />
        </Form.Item>

        {panelType === 'edit' && (
          <div>
            <Form.Item
              name="token"
              label="邀请链接"
              extra={
                <div>
                  <Button onClick={handleGetGroupUrl} size="small" color="primary" fill="none">
                    获取
                  </Button>
                  <Button disabled={!isManager} onClick={handleDeleteGroupUrl} size="small" color="danger" fill="none">
                    删除
                  </Button>
                </div>
              }>
              <Input disabled />
            </Form.Item>
            <div style={{ fontSize: 10, color: 'var(--theme-text-color)' }}>
              <h3>提示:</h3>
              <p>1. 点击获取，会复制邀请链接到剪切板，若无链接会先生成</p>
              <p>2. 将邀请链接发给好友，对方从该链接进入即可加入群聊</p>
              <p>3. 删除链接后，其他人将无法再通过旧链接加入群聊</p>
            </div>
          </div>
        )}
      </Form>
    </div>
  )
}

export default forwardRef(GroupChatEditor)
