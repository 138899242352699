import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { CapsuleTabs, Dialog, Button, Radio, Selector, Slider, Space, Stepper, Toast } from 'antd-mobile'
import cookie from 'react-cookies'
import './MatchSetting.css'
import { updateUserApi } from '@/services/user'

function MatchSetting(props, ref) {
  const { user } = props

  const [isDisabled, setIsDisabled] = React.useState(true)
  const [selectGender, setSelectGender] = React.useState('any')
  const [selectPosition, setSelectPosition] = React.useState('any')
  const [minAge, setMinAge] = React.useState(18)
  const [maxAge, setMaxAge] = React.useState(70)
  const [selectWard, setSelectWard] = React.useState('default')
  const [loadingVisible, setLoadingVisible] = React.useState(false)

  const wardRef = React.useRef(null)

  const wardMap = {
    default: '畅聊',
    green: '清流',
  }

  useEffect(() => {
    const matchSetting = cookie.load('matchSetting')
    if (user.is_vip || user.is_admin || (user.likes && user.likes >= 200)) {
      if (matchSetting) {
        setSelectGender(matchSetting.gender)
        setSelectPosition(matchSetting.position)
        setMinAge(matchSetting.minAge)
        setMaxAge(matchSetting.maxAge)
      }
      setIsDisabled(false)
    }
    setSelectWard(user.ward)
  }, [user])

  function saveCookie(key, value) {
    let matchSetting = cookie.load('matchSetting')
    if (!matchSetting) {
      matchSetting = { gender: 'any', position: 'any', minAge: 18, maxAge: 70, ward: 'default' }
    }
    matchSetting[key] = value
    let cookieTime = new Date(new Date().getTime() + 999 * 24 * 3600 * 1000)
    cookie.save('matchSetting', matchSetting, { expires: cookieTime })
  }

  function handleReset() {
    setSelectGender('any')
    setSelectPosition('any')
    setMinAge(18)
    setMaxAge(70)
    const matchSetting = { gender: 'any', position: 'any', minAge: 18, maxAge: 70, ward: 'default' }
    let cookieTime = new Date(new Date().getTime() + 999 * 24 * 3600 * 1000)
    cookie.save('matchSetting', matchSetting, { expires: cookieTime })
  }

  useImperativeHandle(ref, () => ({
    selectGender,
    selectPosition,
    minAge,
    maxAge,
  }))

  async function handleAction(e) {
    if (e.key === 'ok') {
      if (wardRef.current) {
        const { code, msg } = await updateUserApi({ ward: wardRef.current })
        if (code !== 200) {
          Toast.show({ icon: 'fail', content: msg })
          return
        }
        setSelectWard(wardRef.current)
      }
    }
    setLoadingVisible(false)
  }

  return (
    <div className="match-set-wrapper">
      <Dialog
        visible={loadingVisible}
        onAction={handleAction}
        actions={[
          [
            {
              key: 'cancel',
              text: '取消',
            },
            {
              key: 'ok',
              text: '确认',
              bold: true,
            },
          ],
        ]}
        title="选择分区"
        content={
          <div>
            <div style={{ fontSize: 14 }}>
              <div>只能匹配到同一分区的用户</div>
              <div style={{ color: 'red' }}>清流分区将获得额外魅力值奖励</div>
            </div>

            <CapsuleTabs
              defaultActiveKey={selectWard}
              onChange={(val) => {
                wardRef.current = val
              }}>
              <CapsuleTabs.Tab title="畅聊" key="default" />
              <CapsuleTabs.Tab title="清流" key="green" />
            </CapsuleTabs>
            <div style={{ color: 'var(--adm-color-weak)', fontSize: 14, marginTop: 4 }}>
              <div>清流分区过去12小时被3人举报，并人工核实有违规消息后将无法再次进入</div>
              <div style={{ color: 'red' }}>恶意大量拉黑其他用户将直接封号</div>
            </div>
          </div>
        }
        closeOnMaskClick
        onClose={() => {
          setLoadingVisible(false)
        }}
        bodyStyle={{ opacity: 0.9 }}
      />

      <div className="match-set-item">
        <span className="setting-label">性别： </span>
        <Radio.Group
          disabled={isDisabled}
          value={selectGender}
          onChange={(val) => {
            setSelectGender(val)
            saveCookie('gender', val)
          }}>
          <Space direction="horizontal">
            <Radio value="male">男</Radio>
            <Radio value="female">女</Radio>
            <Radio value="any">任意</Radio>
          </Space>
        </Radio.Group>
      </div>

      <div className="match-set-item" style={{ marginTop: '16px' }}>
        <span className="setting-label">位置： </span>
        <Radio.Group
          disabled={isDisabled}
          value={selectPosition}
          onChange={(val) => {
            if (!user.show_location) {
              Toast.show({ icon: 'fail', content: '请先在设置页开启显示位置' })
              return
            }
            if (!user.location || user.location === '') {
              Toast.show({ icon: 'fail', content: '未能获取到你的位置，请刷新页面后重试' })
              return
            }
            setSelectPosition(val)
            saveCookie('position', val)
          }}>
          <Space direction="horizontal">
            <Radio value="province">同省</Radio>
            {/* <Radio value="city">同城市</Radio> */}
            <Radio value="any">任意</Radio>
          </Space>
        </Radio.Group>
      </div>

      <div className="match-set-item" style={{ marginTop: '16px', lineHeight: '28px' }}>
        <span className="setting-label">年龄： </span>
        <Stepper
          disabled={isDisabled}
          min={18}
          max={70}
          digits={0}
          style={{ marginRight: '10px' }}
          value={minAge}
          onChange={(value) => {
            setMinAge(value)
            saveCookie('minAge', value)
          }}
        />
        到
        <Stepper
          disabled={isDisabled}
          min={18}
          max={70}
          digits={0}
          style={{ marginLeft: '10px' }}
          value={maxAge}
          onChange={(value) => {
            setMaxAge(value)
            saveCookie('maxAge', value)
          }}
        />
      </div>

      <div>
        <Button style={{ marginTop: 30, width: 100 }} color="primary" onClick={handleReset}>
          重置
        </Button>
      </div>

      {/* <div
        className="match-set-item"
        style={{ marginTop: '16px', fontSize: 16, padding: '0 6px', backgroundColor: '#80cd07' }}
        onClick={() => {
          setLoadingVisible(true)
        }}>
        <span className="setting-label">选择分区： </span>
        <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>{wardMap[selectWard]} </span>
      </div>
      <div>
        <span style={{ fontSize: 10, color: 'var(--adm-color-weak)' }}>清流分区将获得额外魅力值奖励</span>
      </div> */}
    </div>
  )
}

export default forwardRef(MatchSetting)
