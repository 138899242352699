import { getDebugUrlApi } from '@/services/crm'
import { Toast, Dialog, List, SwipeAction, InfiniteScroll, Collapse, Divider, Button } from 'antd-mobile'
import { useEffect, useState } from 'react'
import UpdateLikes from '../admin-manage/UpdateLikes'
import SetVipOrder from '../admin-manage/SetVipOrder'
import DialogListItem from './dialog-list-item/DialogListItem'
import copy from 'copy-to-clipboard'
import Tips from '../common/Tips'
import cookie from 'react-cookies'

export default function DialogListContent(props) {
  const {
    chatMap,
    openChat,
    onLoadMore,
    hasMore,
    handleDeleteChat,
    userMap,
    handleCollectChat,
    handleBlockUser,
    handleReport,
    user,
    handleClearMsgs,
  } = props
  const uid = user.id

  const [showChatTips, setShowChatTips] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      if (!cookie.load('hideChatTips') && Object.keys(chatMap || {}).length < 3) {
        setShowChatTips(true)
      }
    }, 500)
  }, [])

  function loadMore() {
    return onLoadMore()
  }

  async function clearMsgs(chat) {
    let content = '双方聊天记录都将被清空'
    if (chat.isGroupChat) {
      content = '群组内聊天记录都将被清空'
    }
    const result = await Dialog.confirm({
      title: '确认清空消息吗?',
      content: content,
    })
    if (result) {
      handleClearMsgs(chat)
    }
  }

  async function deleteChat(chat) {
    let content = '双方聊天都将被删除'
    if (chat.isGroupChat) {
      content = '确定退出该群组吗？'
      if (chat.uid === uid) {
        content = '确定解散该群组吗？'
      }
    }
    const result = await Dialog.confirm({
      title: chat.isGroupChat ? '提示' : '确认删除吗?',
      content: content,
    })
    if (result) {
      handleDeleteChat(chat.chatid)
    }
  }

  async function blockChat(chat) {
    const result = await Dialog.confirm({
      title: '提示',
      content: '确认拉黑对方吗?',
    })
    if (result) {
      handleBlockUser(chat, true)
    }
  }

  async function getDebugUrl(chat) {
    const { code, data } = await getDebugUrlApi(chat.toUid)
    if (code !== 200) return
    copy(data)
    window.open(data)
  }

  function updateLikes(chat) {
    Dialog.show({
      title: '更新用户魅力值',
      content: <UpdateLikes chat={chat} />,
      closeOnMaskClick: true,
    })
  }

  async function updateVip(chat) {
    Dialog.show({
      title: '设置VIP',
      content: <SetVipOrder chat={chat} />,
      closeOnMaskClick: true,
    })
  }

  function handleChat(chat) {
    return (action, e) => {
      if (action.key === 'delete') {
        deleteChat(chat)
      } else if (action.key === 'collect') {
        handleCollectChat(chat, true)
      } else if (action.key === 'cancel_collect') {
        handleCollectChat(chat, false)
      } else if (action.key === 'block') {
        blockChat(chat)
      } else if (action.key === 'unblock') {
        handleBlockUser(chat, false)
      } else if (action.key === 'clear_msgs') {
        clearMsgs(chat)
      } else if (action.key === 'report') {
        handleReport(chat)
      }
      // admin 用户的操作
      else if (action.key === 'get_debug_url') {
        getDebugUrl(chat)
      } else if (action.key === 'update_likes') {
        updateLikes(chat)
      } else if (action.key === 'update_vip') {
        updateVip(chat)
      }
    }
  }

  function getLeftActions(chat) {
    if (!user.is_admin) return []
    if (chat.isGroupChat) return []
    return [
      {
        key: 'get_debug_url',
        text: 'debug',
        color: 'weak',
      },
      {
        key: 'update_likes',
        text: '魅力值',
        color: 'success',
      },
      {
        key: 'update_vip',
        text: '设置VIP',
        color: 'warning',
      },
    ]
  }

  function getRightActions(chat) {
    let action1 = {
      key: 'collect',
      text: '收藏',
      color: 'success',
    }
    if (chat.isCollect) {
      action1 = {
        key: 'cancel_collect',
        text: '取消收藏',
        color: 'weak',
      }
    }

    let action2 = {
      key: 'block',
      text: '拉黑',
    }
    if (chat.isBlock) {
      action2 = {
        key: 'unblock',
        text: '取消拉黑',
        color: 'warning',
      }
    }

    let action3 = {
      key: 'clear_msgs',
      text: '清空记录',
      color: 'warning',
    }
    if (chat.otherLeave) {
      action3 = {
        key: 'report',
        text: '举报并拉黑',
        color: 'warning',
      }
    }

    let rightActions = [
      action1,
      action3,
      {
        key: 'delete',
        text: '删除',
        color: 'danger',
      },
      action2,
    ]
    // gpt不能被拉黑
    if (chat.isGpt) {
      rightActions.pop()
    }

    if (chat.isGroupChat) {
      if (chat.uid === uid) {
        rightActions = [
          {
            key: 'clear_msgs',
            text: '清空记录',
            color: 'warning',
          },
          {
            key: 'delete',
            text: '解散群组',
            color: 'danger',
          },
        ]
      } else {
        rightActions = [
          {
            key: 'delete',
            text: '退出群组',
            color: 'danger',
          },
        ]
      }
    }
    return rightActions
  }

  function getChats() {
    let chats1 = []
    let chats2 = []
    let chats3 = []

    Object.keys(chatMap || {}).forEach((chatid) => {
      let chat = chatMap[chatid]
      if (userMap[chat.toUid]) {
        const toUser = userMap[chat.toUid]
        if (toUser.avatar) {
          chat.avatar = toUser.avatar
        }
        if (toUser.remark_name || toUser.nickname) {
          chat.title = toUser.remark_name || toUser.nickname
        }
        if (toUser.status) {
          chat.status = toUser.status
        }
      }
      if (chat.isGroupChat) {
        chats3.push(chat)
      } else {
        if (chat.isCollect) {
          chats1.push(chat)
        } else {
          chats2.push(chat)
        }
      }
    })
    chats3.sort((a, b) => {
      return b.lastMsgDate - a.lastMsgDate
    })
    chats1.sort((a, b) => {
      return b.lastMsgDate - a.lastMsgDate
    })
    chats2.sort((a, b) => {
      return b.lastMsgDate - a.lastMsgDate
    })
    return { chats1, chats2, chats3 }
    // return chats3.concat(chats1.concat(chats2))
  }

  const { chats1, chats2, chats3 } = getChats()
  const otherChats = chats3.concat(chats1.concat(chats2))

  function handleHideTips() {
    let cookieTime = new Date(new Date().getTime() + 7 * 24 * 3600 * 1000)
    cookie.save('hideChatTips', 1, { expires: cookieTime })
    setShowChatTips(false)
  }

  // const otherChats = chats3.concat(chats2)

  function getListItem(chat) {
    if (chat.id)
      return (
        <SwipeAction
          key={chat.id}
          onAction={handleChat(chat)}
          leftActions={getLeftActions(chat)}
          rightActions={getRightActions(chat)}>
          <List.Item
            className="dialog-item"
            clickable
            arrow={false}
            onClick={() => openChat(chat)}
            style={{ padding: '0px 0px 0px 12px', textDecoration: 'none' }}>
            <DialogListItem {...chat} />
          </List.Item>
        </SwipeAction>
      )
  }

  return (
    <div style={{ display: Object.keys(chatMap || {}).length === 0 ? 'none' : 'block' }}>
      <List>
        {/* {chats1.length && (
          <Collapse defaultActiveKey={['1']}>
            <Collapse.Panel key="1" title={<div style={{ textAlign: 'center' }}>已收藏的聊天</div>}>
              {chats1.map((chat) => {
                return getListItem(chat)
              })}
            </Collapse.Panel>
          </Collapse>
        )} */}
        {otherChats.map((chat) => {
          return getListItem(chat)
        })}
      </List>
      <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />

      {showChatTips && (
        <div style={{ padding: 12 }}>
          <div style={{ width: '90%', margin: '0 auto' }}>
            <Divider style={{ margin: '0px 0px' }}></Divider>
            <Tips />
            <div style={{ textAlign: 'center' }}>
              <Button color="primary" size="small" fill="outline" onClick={handleHideTips}>
                七天不再显示
              </Button>
            </div>

            <Divider style={{ margin: '14px 0px' }}></Divider>
          </div>
        </div>
      )}
    </div>
  )
}
